// @flow
import { all, call, fork, takeEvery, put } from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
    REQUESTED_EXAMS,
    SET_EXAMS,
    FAIL_SET,
} from './constants';
import {
    requested_exams,
    sucess_set_exams,
    fail_set_exams
} from './actions';

function* search_exams() {
    try {
        const response = yield call(api.get, `/exams`);
        
        yield put(sucess_set_exams(response.data))

    } catch (error) {
        console.log(error)
    }
}


export function* watchExams() {
    yield takeEvery(REQUESTED_EXAMS, search_exams);
}

function* examsSaga() {
    yield all([fork(watchExams) ]);
}

export default examsSaga;