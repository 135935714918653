import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const Exams = React.lazy(() => import('../pages/apps/Exams'));
const NewExam = React.lazy(() => import('../pages/apps/Exams/Create'));
const ExamsDetails = React.lazy(() => import('../pages/apps/Exams/Detail'));

const Users = React.lazy(() => import('../pages/apps/Users'));
const UserProfile = React.lazy(() => import('../pages/apps/Users/Profile'));

const Settings = React.lazy(() => import('../pages/apps/Settings'));

const ClinicsList = React.lazy(() => import('../pages/apps/Clinics'));
const ClinicDetail = React.lazy(() => import('../pages/apps/Clinics/Detail/'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />,
    route: PrivateRoute,
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Menu',
    // badge: {
    //     variant: 'success',
    //     text: '3',
    // },
    component: Dashboard,
    roles: ['Administrador', 'Veterinario', 'Clinica', 'Cliente'],
    route: PrivateRoute
};

// apps

const ExamsRoutes = {
    path: '/apps/exams',
    name: 'Exames',
    // header: 'Exames',
    icon: FeatherIcon.Activity,
    component: Exams,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario', 'Clinica', 'Cliente'],
};

const ExamsDetailRoutes = {
    path: '/apps/exams/details/:id',
    name: 'Detalhes do Exame',
    // header: 'Exames',
    icon: FeatherIcon.FileText,
    component: ExamsDetails,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario', 'Clinica', 'Cliente'],
};

const NewExamsRoutes = {
    path: '/apps/exams/new',
    name: 'Novo Exame',
    // header: 'Exames',
    icon: FeatherIcon.FileText,
    component: NewExam,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario'],
};

const UsersRoutes = {
    path: '/apps/users',
    name: 'Usuários',
    // header: 'Exames',
    icon: FeatherIcon.Users,
    component: Users,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario'],
};

const SettingRoutes = {
    path: '/apps/settings',
    name: 'Configurações',
    // header: 'Exames',
    icon: FeatherIcon.Settings,
    component: Settings,
    route: PrivateRoute,
    roles: ['Administrador'],
};

const UserProfileRotes = {
    path: '/apps/users/profile/:id',
    name: 'Perfil do usuário',
    // header: 'Exames',
    icon: FeatherIcon.User,
    component: UserProfile,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario', 'Clinica', 'Cliente'],
};

const clinicsAppRoutes = {
    path: '/apps/clinics/list',
    name: 'Clinicas',
    icon: FeatherIcon.Briefcase,
    component: ClinicsList,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario'],
}

const clinicsDetailRoutes = {
    path: '/apps/clinics/detail/:id',
    name: 'Detail',
    component: ClinicDetail,
    route: PrivateRoute,
    roles: ['Administrador', 'Veterinario', 'Clinica', 'Cliente'],
}


const loggedInUser = getLoggedInUser();
const appRoutes =  loggedInUser ? (loggedInUser.role === 'Administrador' ? [ExamsRoutes, UsersRoutes, clinicsAppRoutes, SettingRoutes] : [ExamsRoutes]) : [ExamsRoutes];

// const appRoutes =  [ExamsRoutes, UsersRoutes, clinicsAppRoutes];


// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    dashboardRoutes,
    NewExamsRoutes,
    ExamsDetailRoutes,
    UserProfileRotes,
    clinicsDetailRoutes,
    ...appRoutes,
    authRoutes,
];

const authProtectedRoutes = [dashboardRoutes, ...appRoutes]//, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
