/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */

import axios from 'axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();
const token_cookie = cookies.get('user');

const api = axios.create({
    //baseURL: 'http://localhost:5000'
    // baseURL: 'https://vetscan-diag.herokuapp.com/'
    baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000'
});
if(token_cookie) {
    api.defaults.headers.Authorization = `Bearer ${token_cookie.token}`;
}

const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};

export { fetchJSON, api };
