import {
    REQUESTED_USERS,
    SET_USERS,
    FAIL_SET,
} from './constants';

const INIT_STATE = {
    loading: false,
    users: []
};

const UsersReducer = (state = INIT_STATE, action) => { 
    switch (action.type) {
        case REQUESTED_USERS:
            return{
                loading: true,
            };
        case SET_USERS:
            return{
                ...state, 
                users: action.payload,
                loading: false,
            };
        case FAIL_SET:
            return{
                loading: false,
            };
        default:
            return state;
    }
}

export default UsersReducer;