// @flow
import { all, call, fork, takeEvery, put } from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
    REQUESTED_USERS,
    SET_EXAMS,
    FAIL_SET,
} from './constants';
import {
    requested_users,
    sucess_set_users,
    fail_set_users
} from './actions';

function* search_users() {
    try {
        const response = yield call(api.get, `/users/all`);
        
        yield put(sucess_set_users(response.data))

    } catch (error) {
        console.log(error)
    }
}


export function* watchUsers() {
    yield takeEvery(REQUESTED_USERS, search_users);
}

function* usersSaga() {
    yield all([fork(watchUsers) ]);
}

export default usersSaga;