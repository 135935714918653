import {
    REQUESTED_EXAMS,
    SET_EXAMS,
    FAIL_SET,
} from './constants';


export const requested_exams = () => ({
    type: REQUESTED_EXAMS,
});

export const sucess_set_exams = (exams) => ({
    type: SET_EXAMS,
    payload: exams,
});

export const fail_set_exams = () => ({
    type: FAIL_SET,
});