import {
    REQUESTED_USERS,
    SET_USERS,
    FAIL_SET,
} from './constants';


export const requested_users = () => ({
    type: REQUESTED_USERS,
});

export const sucess_set_users = (users) => ({
    type: SET_USERS,
    payload: users,
});

export const fail_set_users = () => ({
    type: FAIL_SET,
});