import {
    REQUESTED_EXAMS,
    SET_EXAMS,
    FAIL_SET,
} from './constants';

const INIT_STATE = {
    loading: false,
    exams: []
};

const ExamsReducer = (state = INIT_STATE, action) => { 
    switch (action.type) {
        case REQUESTED_EXAMS:
            return{
                loading: true,
            };
        case SET_EXAMS:
            return{
                ...state, 
                exams: action.payload,
                loading: false,
            };
        case FAIL_SET:
            return{
                loading: false,
            };
        default:
            return state;
    }
}

export default ExamsReducer;